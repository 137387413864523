import {useCurrencyFormatter as useInfraCurrencyFormatter, useTranslation} from '@wix/yoshi-flow-editor'
import {getCountry, getLocale} from '../../../commons/selectors/environment'
import {getCurrencyFormatter} from '../../../commons/utils/currency-formatter'
import {useMembersPageState} from './state'

export const useMembersPageCurrencyFormatter = () => {
  const locale = useMembersPageState(getLocale)
  const country = useMembersPageState(getCountry)
  const formatCurrency = useInfraCurrencyFormatter({language: locale, country})
  const {t} = useTranslation()

  return getCurrencyFormatter({t, formatCurrency})
}
