import {membersPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import React from 'react'
import {EventText} from '../../../../../commons/components/event-text'
import {Routes} from '../../../../../commons/enums'
import {useCommonDateInformation} from '../../../../../commons/hooks/dates'
import {useMembersPageCurrencyFormatter} from '../../../hooks/currency'
import {Back} from '../back'
import {ExpandableRow} from '../expandable-row'
import {MobileOrderListProps} from './interfaces'
import s from './mobile-order-list.scss'

export const MobileOrderList = (props: MobileOrderListProps) => {
  const {orders, event, internalNavigate, t} = props
  return (
    <div className={s.root}>
      <Back onClick={() => internalNavigate(Routes.DEFAULT)} t={t} />
      {orders.map((order: wix.events.ticketing.Order, idx: number) => (
        <OrderRow
          key={order.orderNumber}
          order={order}
          lastRow={idx === orders.length - 1}
          onClick={() => internalNavigate(Routes.ORDER, {event, order})}
          {...props}
        />
      ))}
    </div>
  )
}

const OrderRow = ({order, lastRow, onClick, t}) => {
  const {orderNumber} = order
  const orderDate = useCommonDateInformation(orderNumber)
  const {getFormattedMoney} = useMembersPageCurrencyFormatter()

  return (
    <ExpandableRow
      mobile
      navigable
      key={order.orderNumber}
      divider={!lastRow}
      toggle={onClick}
      arrowClass={s.arrow}
      t={t}
    >
      <div className={s.content}>
        <div data-hook={DH.MOBILE_ORDER_NUMBER}>
          <EventText>{t('mobile.order.number', {orderNumber})}</EventText>
        </div>
        <div className={s.date} data-hook={DH.MOBILE_ORDER_DATE}>
          <EventText faded>{t('mobile.order.date', {date: orderDate})}</EventText>
        </div>
        <div className={s.total} data-hook={DH.MOBILE_ORDER_TOTAL}>
          <EventText>{t('mobile.order.total', {total: getFormattedMoney(order.invoice.grandTotal)})}</EventText>
        </div>
      </div>
    </ExpandableRow>
  )
}
