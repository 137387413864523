import {membersPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useTranslation} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React from 'react'
import {EventText} from '../../../../../../commons/components/event-text'
import {useCommonDateInformation} from '../../../../../../commons/hooks/dates'
import {getTicketsWithoutTax} from '../../../../../../commons/selectors/tickets'
import {DownloadTicketsButton} from '../../actions/download-tickets-button'
import {Divider} from '../../styled-components/divider'
import {DottedDivider} from '../../styled-components/dotted-divider'
import {TicketWithSeating} from '../../ticket-with-seating'
import {OrderSummary} from '../order-summary'
import {OrderWithSeatingProps} from './interfaces'
import s from './order-with-seating.scss'

const TableHeader = ({t}) => (
  <div className={classNames(s.row)}>
    <div className={s.colTicket}>
      <EventText>{t('order.header.ticket-type')}</EventText>
    </div>
    <div className={s.colSeatInfo}>
      <EventText>{t('order.header.seatInfo')}</EventText>
    </div>
    <div className={s.colTotal}>
      <EventText>{t('order.header.price')}</EventText>
    </div>
  </div>
)

export const OrderWithSeating = ({order, event, isOwner}: OrderWithSeatingProps) => {
  const {t} = useTranslation()
  const {orderNumber, invoice} = order
  const orderDate = useCommonDateInformation(orderNumber)
  const tickets = getTicketsWithoutTax(order)

  return (
    <div className={s.root} data-hook={DH.ORDER_DETAILS}>
      <DottedDivider />
      <div className={s.content}>
        <div className={s.header}>
          <EventText>{t('order.number', {orderNumber})}</EventText>
          <EventText>{t('order.date', {date: orderDate})}</EventText>
        </div>
        {isOwner ? (
          <div className={s.downloadOrderTicketsContainer}>
            <DownloadTicketsButton event={event} order={order} />
          </div>
        ) : null}
        <Divider />
        <TableHeader t={t} />
        <Divider />
        {tickets.map(item => (
          <TicketWithSeating key={item.ticketNumber} ticket={item} />
        ))}
        <Divider />
        <OrderSummary invoice={invoice} />
      </div>
    </div>
  )
}
