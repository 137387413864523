export const getCurrencyFormatter = ({t, formatCurrency}) => {
  return {
    getFormattedMoney: (price: wix.events.Money): string => {
      if (!price.currency) {
        return price.amount
      }
      return formatCurrency({value: price.amount, currency: price.currency})
    },
    getTicketPriceText: (price: wix.events.Money): string => {
      const amount = Number(price.amount)

      if (!amount) {
        return t('ticketPrice.free')
      }

      if (!price.currency) {
        return amount.toString()
      }

      return formatCurrency({value: price.amount, currency: price.currency})
    },
  }
}
